import { call, put, takeLatest } from "redux-saga/effects";
import {
  getChatsList,
  getMessagesListByChatId,
  sendMessage,
  pinMessages,
  unpinMessages,
  markUsRead,
  getPinnedMessages,
  likeMessages,
  dislikeMessages,
} from "../../crud/chats.crud";
import {
  START_LOADING_CHATS,
  STOP_LOADING_CHATS,
  SET_CURRENT_CHATS_LIST,
  FETCH_CHATS,
  START_LOADING_MESSAGES,
  STOP_LOADING_MESSAGES,
  FETCH_MESSAGES,
  SET_CURRENT_MESSAGE_LIST,
  SET_CURRENT_CHAT_ID,
  START_SENDING_MESSAGE,
  STOP_SENDING_MESSAGE,
  SEND_MESSAGE,
  SET_NEW_MESSAGE,
  START_READING_MESSAGES,
  STOP_READING_MESSAGES,
  READ_MESSAGES,
  START_PIN_MESSAGES,
  STOP_PIN_MESSAGES,
  PIN_MESSAGES,
  START_UNPIN_MESSAGES,
  STOP_UNPIN_MESSAGES,
  UNPIN_MESSAGES,
  PIN_MESSAGES_ACTION,
  UNPIN_MESSAGES_ACTION,
  READ_MESSAGES_ACTION,
  START_PINNED_MESSAGES,
  STOP_PINNED_MESSAGES,
  PINNED_MESSAGES,
  PINNED_MESSAGES_ACTION,
  GET_MODE_START,
  GET_MODE_END,
  GET_MORE_CHATS,
  GET_MORE_CHATS_ACTION,
  START_LIKE_MESSAGES,
  STOP_LIKE_MESSAGES,
  LIKE_MESSAGES,
  LIKE_MESSAGES_ACTION,
  START_DISLIKE_MESSAGES,
  STOP_DISLIKE_MESSAGES,
  DISLIKE_MESSAGES,
  DISLIKE_MESSAGES_ACTION,
} from "../constants";

function* fetchChatsSaga(action) {
  try {
    yield put({ type: START_LOADING_CHATS });
    const chats = yield call(getChatsList, action.params);
    yield put({ type: SET_CURRENT_CHATS_LIST, payload: chats.data });
  } catch (error) {
    console.error("Error fetching chats:", error);
  } finally {
    yield put({ type: STOP_LOADING_CHATS });
  }
}

function* fetchMoreChatsSaga(action) {
  try {
    yield put({ type: GET_MODE_START });
    const chats = yield call(getChatsList, action.params);
    yield put({ type: GET_MORE_CHATS, payload: chats.data });
  } catch (error) {
    console.error("Error fetching chats:", error);
  } finally {
    yield put({ type: GET_MODE_END });
  }
}

function* fetchMessagesSaga(action) {
  try {
    yield put({ type: START_LOADING_MESSAGES });
    const messages = yield call(getMessagesListByChatId, action.payload);
    yield put({ type: SET_CURRENT_MESSAGE_LIST, payload: messages.data.data });
  } catch (error) {
    console.error("Error fetching messages:", error);
  } finally {
    yield put({ type: STOP_LOADING_MESSAGES });
  }
}

function* sendMessageSaga(action) {
  try {
    yield put({ type: START_SENDING_MESSAGE });

    const messages = yield call(
      sendMessage,
      action.payload.message,
      action.payload.chatRoomId
    );
    yield put({ type: SET_NEW_MESSAGE, payload: messages.data.data });
  } catch (error) {
    console.error("Error fetching messages:", error);
  } finally {
    yield put({ type: STOP_SENDING_MESSAGE });
  }
}

function* pinMessageSaga(action) {
  try {
    yield put({ type: START_PIN_MESSAGES });

    yield call(
      pinMessages,
      { messages: action.payload.messages },
      action.payload.chatRoomId
    );
    yield put({
      type: PIN_MESSAGES,
      payload: {
        ids: action.payload.messages,
        chat_id: action.payload.chatRoomId,
        message: action.payload.message,
      },
    });
  } catch (error) {
    console.error("Error pinned messages:", error);
  } finally {
    yield put({ type: STOP_PIN_MESSAGES });
  }
}

function* unpinMessageSaga(action) {
  try {
    yield put({ type: START_UNPIN_MESSAGES });

    yield call(
      unpinMessages,
      { messages: action.payload.messages },
      action.payload.chatRoomId
    );
    yield put({
      type: UNPIN_MESSAGES,
      payload: {
        ids: action.payload.messages,
        chat_id: action.payload.chatRoomId,
        message: action.payload.message,
      },
    });
  } catch (error) {
    console.error("Error pinned messages:", error);
  } finally {
    yield put({ type: STOP_UNPIN_MESSAGES });
  }
}
function* readMessageSaga(action) {
  try {
    yield put({ type: START_READING_MESSAGES });

    const messages = yield call(
      markUsRead,
      { text: action.payload.text },
      action.payload.chatRoomId
    );
    yield put({ type: READ_MESSAGES, payload: messages.data.data });
  } catch (error) {
    console.error("Error pinned messages:", error);
  } finally {
    yield put({ type: STOP_READING_MESSAGES });
  }
}

function* getPinnedMessagesSaga(action) {
  try {
    yield put({ type: START_PINNED_MESSAGES });

    const messages = yield call(getPinnedMessages, action.payload.chatRoomId);
    yield put({ type: PINNED_MESSAGES, payload: messages.data });
  } catch (error) {
    console.error("Error pinned messages:", error);
  } finally {
    yield put({ type: STOP_PINNED_MESSAGES });
  }
}

function* likeMessageSaga(action) {
  try {
    yield put({ type: START_LIKE_MESSAGES });

    yield call(
      likeMessages,
      action.payload.chatRoomId,
      action.payload.messageId
    );

    yield put({
      type: LIKE_MESSAGES,
      payload: {
        messageId: action.payload.messageId,
        chat_id: action.payload.chatRoomId,
        meId: action.payload.meId,
      },
    });
  } catch (error) {
    console.error("Error like messages:", error);
  } finally {
    yield put({ type: STOP_LIKE_MESSAGES });
  }
}

function* dislikeMessageSaga(action) {
  try {
    yield put({ type: START_DISLIKE_MESSAGES });

    yield call(
      dislikeMessages,
      action.payload.chatRoomId,
      action.payload.messageId
    );
    yield put({
      type: DISLIKE_MESSAGES,
      payload: {
        messageId: action.payload.messageId,
        chat_id: action.payload.chatRoomId,
        meId: action.payload.meId,
      },
    });
  } catch (error) {
    console.error("Error like messages:", error);
  } finally {
    yield put({ type: STOP_DISLIKE_MESSAGES });
  }
}

export function* watchFetchChatsSaga() {
  yield takeLatest(FETCH_CHATS, fetchChatsSaga);
}

export function* watchFetchMoreChatsSaga() {
  yield takeLatest(GET_MORE_CHATS_ACTION, fetchMoreChatsSaga);
}

export function* watchFetchMessagesSaga() {
  yield takeLatest(FETCH_MESSAGES, fetchMessagesSaga);
}

export function* watchCurrentChatIdChange() {
  yield takeLatest(SET_CURRENT_CHAT_ID, fetchMessagesSaga);
}

export function* watchSendMessage() {
  yield takeLatest(SEND_MESSAGE, sendMessageSaga);
}

export function* watchPinMessage() {
  yield takeLatest(PIN_MESSAGES_ACTION, pinMessageSaga);
}
export function* watchUnpinMessage() {
  yield takeLatest(UNPIN_MESSAGES_ACTION, unpinMessageSaga);
}

export function* watchReadMessage() {
  yield takeLatest(READ_MESSAGES_ACTION, readMessageSaga);
}
export function* watchPinnedMessage() {
  yield takeLatest(PINNED_MESSAGES_ACTION, getPinnedMessagesSaga);
}

export function* watchLikeMessage() {
  yield takeLatest(LIKE_MESSAGES_ACTION, likeMessageSaga);
}

export function* watchDislikeMessage() {
  yield takeLatest(DISLIKE_MESSAGES_ACTION, dislikeMessageSaga);
}

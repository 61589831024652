import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Loader from "../../../../../components/Loader";
import styled from "styled-components";
import { Pagination, Button } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
  OutlinedInput,
  Typography,
  Avatar,
  Tab,
  Tabs,
} from "@material-ui/core";

import TableList from "../../../../../components/TableList";
import { Link } from "react-router-dom";

import { useUsers } from "../../hooks/useUsers";
import { useGroups } from "../../hooks/useGroups";
import { useChatsList } from "../../hooks/useChatsList";
import { FlaggedColumn } from "./components/FlaggedColumn";
import { ImgComponent } from "../../../Sales/helpers/ImgComponent";
import { ReadColumn } from "./components/ReadColumn";
import { AttachmentsColumn } from "./components/AttachmentsColumn";
import { formatTime } from "../../helpers/formatTime";
import { OpenChatColumn } from "./components/OpenChatColumn";
import { useContext } from "react";
import { SocketContext } from "../../../../../services/socket/socket";

import "../../../table.scss";
import "../../../style.scss";
import UploadFile from "../SendMessage/components/UploadFile/UploadFile";

const initSetting = {};

export const ChatsList = (props) => {
  document.title = "TrainerMetrics - Chats";
  const { chatRoom } = useContext(SocketContext);

  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const role = useSelector((state) => state.auth?.user?.roles);
  const dispatch = useDispatch();

  const [check, setCheck] = useState([]);

  const {
    loading,
    error,
    data,
    search,
    setSearch,
    sort,
    setSort,
    staff,
    setStaff,
    groups,
    setGroups,
    setStatus,
    status,
    pagination,
    perPage,
    setPage,
    setPerPage,
    date,
    setData,
  } = useChatsList(initSetting);
  useEffect(() => {
    if (chatRoom) {
      chatRoom.bind("message-created", ({ message }) => {
        setData((prev) =>
          prev.map((item) =>
            item.values.id === message.chat_room_id
              ? {
                  values: {
                    ...item.values,
                    is_read: 0,
                    last_message_text: message.text,
                    last_message_created_at: message.created_at,
                  },
                }
              : item
          )
        );
      });
    }
  }, [chatRoom, date?.length]);

  const { userList, loading: loaderUser } = useUsers({
    groups: groups.filter(Boolean),
  });
  const { groupList, loading: loaderAllGroups } = useGroups();

  const [logout, setLogout] = useState(null);

  //redirect if no have access
  useEffect(() => {
    if (role === "staff" || role === "client") {
      dispatch(
        AlertState.actions.alert({
          text: "User does not have the right roles",
          variant: false,
        })
      );
      setLogout(<Redirect to="/profile-list" />);
    }
  }, [role]);

  useEffect(() => {
    if (error) {
      dispatch(
        AlertState.actions.alert({
          text: error?.response?.data?.message || "Something went wrong!",
          variant: false,
        })
      );
    }
  }, [error]);

  const header = [
    {
      name: "profile",
      title: "Profile",
    },
    {
      name: "user_full_name",
      title: "Staff",
    },
    {
      name: "last_message_text",
      title: "Message Preview",
      render: (value) => <p className="ellipsis-text">{value}</p>,
    },

    {
      name: "is_read",
      title: "Read/Unread",
      render: (value) => <ReadColumn value={value} />,
    },
    {
      name: "attachments",
      title: "Attachments",
      render: (value) => <AttachmentsColumn value={value} />,
    },
    {
      name: "last_message_created_at",
      title: "Last Updated",
      render: formatTime,
    },
    {
      name: "id",
      title: "",
      render: (value, name, record) => <OpenChatColumn record={record} />,
    },
  ];

  return (
    <StyledGroupLists className="nowrap">
      {logout}
      <Loader visible={loaderAllGroups || loaderUser || loading} />

      <Portlet>
        <PortletBody>
          <TabsBlock className="users-list__header-list">
            <Tabs
              value={status}
              onChange={(e, value) => setStatus(value)}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab value={1} label="All"></Tab>
              <Tab value={2} label="Hidden"></Tab>
            </Tabs>
          </TabsBlock>

          <FiltersBlock>
            <div className="btn-toolbar__group">
              <Button
                disabled={!check?.length}
                variant="info"
                onClick={() => null}
                style={{ margin: 5 }}
              >
                Hide
              </Button>
              {plan === "Enterprise" && (
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Select Groups</InputLabel>
                  <Select
                    multiple
                    name="group"
                    onChange={(e, child) => {
                      if (!child.props.value) {
                        setGroups([0]);
                      } else {
                        setGroups(e.target.value.filter(Boolean));
                      }
                      setPage(1);
                      setStaff([]);
                    }}
                    value={groups}
                    input={<OutlinedInput id={`select-multiple-checkbox`} />}
                    renderValue={(selected) => (
                      <SelectedBox>
                        <span>
                          {selected
                            .map(
                              (item) =>
                                [
                                  { name: "(All Groups)", id: 0 },
                                  ...groupList,
                                ].find((elem) => elem.id === item).name
                            )
                            .join(", ")}
                        </span>
                      </SelectedBox>
                    )}
                    inputProps={{
                      name: "groups",
                    }}
                    MenuProps={{
                      autoFocus: false,
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {[{ name: "(All Groups)", id: 0 }, ...groupList].map(
                      (item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

              <FormControl variant="outlined" className="formControl">
                <InputLabel>Select Users</InputLabel>
                <Select
                  multiple
                  value={staff}
                  name="staff"
                  onChange={(e, child) => {
                    if (!child.props.value) {
                      setStaff([0]);
                    } else {
                      setStaff(e.target.value.filter(Boolean));
                    }
                    setPage(1);
                  }}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          [
                            { first_name: "(All Users)", id: 0 },
                            ...userList,
                          ]?.find((elem) => elem.id === item).first_name
                      )
                      .join(", ")
                  }
                  inputProps={{
                    name: "staff",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {[{ first_name: "(All Users)", id: 0 }, ...userList].map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText
                          primary={`${item.first_name} ${item.last_name || ""}`}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>

            <div
              className={`search-wrap`}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <TextField
                key={"search"}
                className="formControl"
                label="Search"
                margin="normal"
                variant="outlined"
                value={search}
                style={{ marginLeft: 0 }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                  }
                }}
              />
            </div>
          </FiltersBlock>
          <TableList
            header={header}
            data={data}
            check={check}
            setCheck={setCheck}
            sort={sort}
            setSort={(newSort) => {
              if (!sort) {
                return setSort(newSort);
              }
              if (sort[0] !== newSort[0]) {
                return setSort([newSort[0], "asc"]);
              }

              if (sort[1] === "desc") {
                return setSort([undefined, undefined]);
              }
              setSort(newSort);
            }}
            plan={plan}
          />
          {!!pagination?.total && (
            <div className="pagination-wrap">
              <Pagination>
                <Pagination.First
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage(1)}
                />
                <Pagination.Prev
                  disabled={pagination.current_page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                />

                <Pagination.Item>{pagination.current_page}</Pagination.Item>

                <Pagination.Next
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage((prev) => prev + 1)}
                />
                <Pagination.Last
                  disabled={pagination.last_page === pagination.current_page}
                  onClick={() => setPage(pagination.last_page)}
                />
              </Pagination>

              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Show Entries</InputLabel>
                  <Select
                    value={perPage}
                    input={<OutlinedInput />}
                    onChange={(e) => {
                      setPerPage(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {`Showing ${pagination.from} - ${pagination.to} fo ${pagination.total}`}
                </Typography>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const EditableCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & .pencil-icon {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & p {
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

const SelectedBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .close-icon {
    margin: 0;
    color: #646c9a;
    padding: 2px;
    position: absolute;
    right: -6px;
    &:hover {
      z-index: 99999999;
    }
  }
`;

import React from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";

export const ChatDrawer = ({ children }) => {
  const isOpen = useSelector((state) => state.chat.open);

  return <Wrapper isOpen={isOpen}>{children}</Wrapper>;
};

const slideIn = keyframes`
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 100px;
  right: 24px;
  top: 60px;
  height: 90vh;
  width: 356px;
  background-color: white;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  z-index: 1000;
  border: 1px solid rgba(226, 226, 226, 1);
  transform: translateX(200%);
  z-index: 10;
  animation: ${({ isOpen }) =>
    isOpen
      ? css`
          ${slideIn} 0.3s forwards
        `
      : css`
          ${slideOut} 0.3s forwards
        `};
`;

import React from "react";
import styled from "styled-components";
import { formatTime } from "../../../../helpers/formatTime";
import { ReadByBlock } from "./components/ReadByBlock/ReadByBlock";
import { MessageActions } from "./components/MessageActions/MessageActions";
import { PinMessage } from "../../../../../../../../assets";
import { MessageMedia } from "./components/MessageMedia/MessageMedia";
import { ParseLinks } from "../../../../helpers/ParseLinks";
import { useSelector } from "react-redux";
import { LikeByBlock } from "./components/LikeByBlock /LikeByBlock";

export const Message = ({ message, isMy }) => {
  const meId = useSelector((state) => state.auth.user.id);

  const isLiked = message?.liked_by?.includes(meId);
  return (
    <Wrapper isMy={isMy} isPinned={!!message?.is_pinned}>
      <BodyWrapper>
        <Body>
          <MessageText isPinned={!!message?.is_pinned} isMy={isMy}>
            <ParseLinks isMy={isMy} text={message.text} />
          </MessageText>
          {!!message?.files?.length && <MessageMedia files={message.files} />}
        </Body>

        <MessageActions
          messageId={message.id}
          isPinned={!!message?.is_pinned}
          isLiked={isLiked}
          isMy={isMy}
          text={message.text}
          message={message}
          meId={meId}
        />
      </BodyWrapper>
      <FooterWrapper>
        <WrapperInner>
          {!!message?.is_pinned && <PinMessage color="red" />}
          {isLiked && <LikeByBlock users={message?.read_by} />}
          <ReadByBlock users={message?.read_by} />
        </WrapperInner>
        <MessageTime isPinned={!!message?.is_pinned} isMy={isMy}>
          {formatTime(message.created_at)}
        </MessageTime>
      </FooterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 280px;
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: ${(props) =>
    props.isPinned ? "#DB000012" : props.isMy ? "#3E6DF6" : "white"};
  border: 1px solid #e2e2e2;
  border-radius: ${(props) =>
    props.isMy ? "12px 12px 0 12px" : "0px 12px 12px 12px"};
  margin-left: ${(props) => (props.isMy ? "auto" : "0")};

  & .message-actions {
    display: none;
    transition: all 0.2s;
  }
  &:hover .message-actions {
    display: block;
    transition: all 0.2s;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: 95% 5%;
`;

const Body = styled.div``;

const MessageText = styled.p`
  color: ${(props) => (props.isMy && !props.isPinned ? "white" : "#1D1D1D")};
  word-break: break-all;
`;
const MessageTime = styled.p`
  color: ${(props) => (props.isMy && !props.isPinned ? "white" : "#9E9E9E")};
  margin: 0;
  position: absolute;
  right: 10px;
  bottom: 4px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: right;
`;

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

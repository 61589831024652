import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import DropZone from "../../../../../Files/DropZone";
import { Checkbox, TextField } from "@material-ui/core";
import Loader from "../../../../../../../components/Loader";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  closeUploadModalAction,
  setFilesAction,
  removeFileAction,
} from "../../../../../../../store/actions";
import { ProgressBar } from "react-bootstrap";
import Cancel from "@material-ui/icons/Cancel";

export default ({ loader, setLoader, tab }) => {
  const isOpen = useSelector((state) => state.chat.modal);
  const value = useSelector((state) => state.chat.files);

  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const [fileDate, setFileDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState({
    name: false,
    test_date: false,
  });
  const [errors, setErrors] = useState({});

  const handleCloseModal = () => {
    dispatch(closeUploadModalAction());
  };

  const onChange = (files) => {
    dispatch(setFilesAction(files));
  };

  const handleRemoveFile = (id) => {
    dispatch(removeFileAction(id));
  };

  useEffect(() => {
    setFileName("");
    setFileDate(moment(new Date()).format("MM/DD/YYYY"));
    setFiles(value);
    setErrors({});
    setTouched({
      name: false,
      test_date: false,
    });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) setFiles(value);
  }, [isOpen]);

  useEffect(() => {
    if (files.length) {
      setFileName(files[files.length - 1].data.file_name);
    }
  }, [files]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSave = () => {
    onChange([...files, ...value]);
    handleCloseModal();
  };

  const Preview = ({ file }) => {
    const { meta, data } = file;
    const { name, percent, status } = meta;

    const type = meta.type.split("/")[0];

    return (
      <div className="fileprogress">
        <span
          className="fileprogress-name"
          style={{ color: status === "error_upload" ? "red" : "" }}
        >
          {name}
        </span>
        <div className="fileprogress__info">
          {type === "image" && (
            <div className="fileprogress__preview-container">
              <img
                src={meta.previewUrl}
                alt="img"
                className="fileprogress__preview"
              />
            </div>
          )}
          <ProgressBar
            animated={
              status !== "done" &&
              status !== "error_upload" &&
              status !== "error_validation"
            }
            now={status !== "error_validation" ? Math.round(percent) : 100}
            className="fileprogress__info-bar"
            variant={
              status === "error_upload" || status === "error_validation"
                ? "danger"
                : status === "done"
                ? "success"
                : ""
            }
          />
          <Cancel
            className="fileprogress__info-remove"
            onClick={() => handleRemoveFile(data.id)}
          />
        </div>
      </div>
    );
  };

  return (
    <UploadModalStyled>
      <Modal show={isOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Attach File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader visible={loader} />
          <DropZone setData={setFiles} />

          <div style={{ marginTop: "8px" }}>
            Max file size:
            {tab === 0 && " 6MB"}
            {tab === 1 && " 50MB"}
            {tab === 2 && " 10MB"}
          </div>
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "235px",
            }}
          >
            Max number of files: 10
          </div>

          <StyledDropZoneFiles>
            {value?.map((file) => (
              <Preview file={file} />
            ))}
          </StyledDropZoneFiles>

          {files.length > 0 &&
            files.find((elem) => elem.meta.type.split("/")[0] === "image") && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={handleChange}
                  checked={checked}
                />
                <div style={{ color: "blue" }}>
                  Check if Uploading Progress Photo
                </div>
              </div>
            )}
          {files.length <= 1 && (
            <TextField
              variant="outlined"
              type="text"
              label="File Name"
              margin="normal"
              className="kt-width-full"
              name="file_name"
              inputProps={{
                maxLength: 300,
              }}
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              helperText={touched.name && errors.name}
              error={Boolean(touched.name && errors.name)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Loader visible={loader} spinner={false} />
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseModal();
              onChange([]);
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-blue btn btn-primary"
            onClick={handleSave}
            disabled={!files.length}
          >
            Attach
          </Button>
        </Modal.Footer>
      </Modal>
    </UploadModalStyled>
  );
};

const UploadModalStyled = styled.div``;

const StyledDropZoneFiles = styled.div`
  position: relative;

  .dzu-dropzone {
    overflow: auto;
  }

  button.dzu-submitButton {
    display: none;
  }

  .fileprogress {
    width: 100%;
    margin: 10px 0;

    &__preview-container {
      width: 60px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    &__preview {
      width: 100%;
      height: 25px;
      object-fit: cover;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-bar {
        width: 100%;
        margin: 2px 10px 2px 0;
      }

      &-remove {
        cursor: pointer;
      }
    }
  }
`;

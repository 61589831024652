import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import objectPath from "object-path";
import HMenuItem from "./HMenuItem";
import * as builder from "../../../ducks/builder";
import KTMenu from "../../../_assets/js/menu";
import KTOffcanvas from "../../../_assets/js/offcanvas";
import {
  fieldsUser,
  fieldsGroups,
  getAccount,
  getOwnGroups,
} from "../../../../app/crud/info.crud";
import { getImg } from "../../../../app/crud/media.crud";
import { useSelector } from "react-redux";
import { setAccount } from "../../../../app/store/actions";
import { useDispatch } from "react-redux";
import "./style.scss";

const offcanvasOptions = {
  overlay: true,
  baseClass: "kt-header-menu-wrapper",
  closeBy: "kt_header_menu_mobile_close_btn",
  toggleBy: {
    target: "kt_header_mobile_toggler",
    state: "kt-header-mobile__toolbar-toggler--active",
  },
};

const allowedCRM = ["abc-fitness"];

const HMenu = (props) => {
  const dispatch = useDispatch();
  const [permUser, setPermUser] = useState(false);
  const [permGroup, setPermGroup] = useState(false);
  const [headerLogo, setHeaderLogo] = useState("");
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );

  const crmList = useSelector((state) => state.user_info.CRM_list);

  const isAllowedSalesModule = crmList.filter(
    (item) => allowedCRM.includes(item.id) && item?.is_activated
  );

  const offCanvasCommonRef = useRef();
  const ktMenuCommonRef = useRef();
  const { refresh } = props;
  const [items, setItems] = useState(props.menuConfig?.header.items);
  const role = useSelector((state) => state.auth.user.roles);
  const [kKTOffcanvas, setKTOffcanvas] = useState(null);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const userId = useSelector((state) => state.auth?.user?.id);
  const [displayGroup, setDisplayGroup] = useState(true);
  const [displayOrganization, setDisplayOrganization] = useState(true);

  const currentUrl = () => {
    return props.location.pathname.split(/[?#]/)[0];
  };

  useEffect(() => {
    if (role === "client") {
      return setItems([]);
    }

    if ((role === "supervisor" || plan) && userId) {
      getOwnGroups(userId).then((res) => {
        if (role === "staff") {
          setDisplayOrganization(false);
          const arr = items.map((elem) => {
            if (elem.title === "Organization") {
              return {
                title: "Profiles",
                root: true,
                aligment: "left",
                page: "profile-list",
                desktop: true,
              };
            } else return { ...elem };
          });
          return setItems(arr);
        }

        if (plan === "Trainer" || plan === "Free") {
          setDisplayGroup(false);
          const arr = items.map((elem) => {
            if (elem.title === "Organization") {
              return {
                title: "Profiles",
                root: true,
                alignment: "left",
                page: "profile-list",
                desktop: true,
              };
            } else return { ...elem };
          });
          return setItems(arr);
        }

        if (
          (role === "supervisor" && res.data.list.length <= 1) ||
          plan === "Gym"
        ) {
          setDisplayGroup(false);
          const arr = items.map((elem) => {
            if (elem.title === "Organization") {
              return {
                title: "Organization",
                root: true,
                alignment: "left",
                page: "profile-list",
                desktop: true,
                submenu: [
                  {
                    title: "Profiles",
                    root: true,
                    aligment: "left",
                    page: "profile-list",
                    desktop: true,
                  },
                  {
                    title: "Users",
                    root: true,
                    alignment: "left",
                    page: "user-list",
                    desktop: true,
                  },
                ],
              };
            } else return { ...elem };
          });
          return setItems(arr);
        }
      });
    }
  }, [userId, role, plan]);

  useEffect(() => {
    // Canvas
    initOffCanvas();
    // Menu
    initKTMenu();
    if (
      props.headerLogo &&
      props.headerLogo.includes("trainermetrics_logo.png")
    ) {
      setHeaderLogo(props.headerLogo);
    } else {
      getImg(props.headerLogo).then((res) => setHeaderLogo(res));
    }
  }, [props.headerLogo]);

  useEffect(() => {
    getAccount({}).then((res) => {
      dispatch(setAccount(res.data.data));
    });

    fieldsUser()
      .then(() => {
        setPermUser(true);
      })
      .catch(() => {
        setPermUser(false);
      });

    fieldsGroups()
      .then(() => {
        setPermGroup(true);
      })
      .catch(() => {
        setPermGroup(false);
      });
  }, [refresh]);

  const initOffCanvas = () => {
    setKTOffcanvas(
      new KTOffcanvas(offCanvasCommonRef.current, offcanvasOptions)
    );
  };

  const initKTMenu = () => {
    let menuOptions = {
      submenu: {
        desktop: "dropdown",
        tablet: "accordion",
        mobile: "accordion",
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = "accordion";
    if (ktMenuCommonRef.current.getAttribute("data-ktmenu-dropdown") === "1") {
      menuDesktopMode = "dropdown";
    }

    if (typeof objectPath.get(menuOptions, "submenu.desktop") === "object") {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    new KTMenu(ktMenuCommonRef.current, menuOptions);
  };

  const {
    // eslint-disable-next-line
    disabledAsideSelfDisplay,
    ktMenuClasses,
    ulClasses,
    rootArrowEnabled,
  } = props;

  return (
    <>
      <button
        className="kt-header-menu-wrapper-close"
        id="kt_header_menu_mobile_close_btn"
      >
        <i className="la la-close" />
      </button>
      <div
        className="kt-header-menu-wrapper"
        id="kt_header_menu_wrapper"
        ref={offCanvasCommonRef}
      >
        <div className="kt-header-logo">
          <Link to="/">
            {headerLogo && <img alt="logo" key={headerLogo} src={headerLogo} />}
          </Link>
        </div>

        <div
          id="kt_header_menu"
          className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
          ref={ktMenuCommonRef}
        >
          <ul className={`kt-menu__nav ${ulClasses}`}>
            {items
              ?.filter((item) => {
                return (
                  (item.page === "user-list" &&
                    permUser &&
                    displayOrganization) ||
                  (item.page === "group-list" &&
                    permGroup &&
                    displayGroup &&
                    displayOrganization) ||
                  item.page === "profile-list" ||
                  item.page === "account/setting" ||
                  item.page === "account/biling" ||
                  item.page === "account/info" ||
                  item.page === "reports-list" ||
                  item.page === "logout" ||
                  (item.page === "account/crm/connect" && crm) ||
                  item.page === "library/forms/list" ||
                  (item.page === "dashboard/main" &&
                    (plan === "Gym" || plan === "Enterprise")) ||
                  (item.page === "analytics" && role !== "client") ||
                  (item.page === "sales" && !!isAllowedSalesModule?.length)
                );
              })
              .map((item, index, arr) => {
                return (
                  <React.Fragment key={`hmenuList${index}`}>
                    {item.title && (
                      <HMenuItem
                        item={item}
                        currentUrl={currentUrl}
                        rootArrowEnabled={rootArrowEnabled}
                        dtnone={arr.filter((item) => item.desktop).length === 1}
                        kKTOffcanvas={kKTOffcanvas}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            <li>
              {(props.role === "super-admin" || props.role === "admin") && (
                <div className="profile-line mobile-none">
                  <div className="profile-line__title">
                    Active Profile Limit
                  </div>
                  <div className="profile-line__elem">
                    <div
                      className="profile-line__value"
                      style={{
                        width: `${(100 / props.profileLimit) *
                          props.active_profiles}%`,
                      }}
                    />
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    refresh: store.auth.refreshStatistic,
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    headerLogo: builder.selectors.getLogo(store),
    profileLimit: store.user_info.subscription?.plan?.active_profiles || 1,
    active_profiles: store.appData.account.active_profiles,
    ktMenuClasses: builder.selectors.getClasses(store, {
      path: "header_menu",
      toString: true,
    }),
    role: store.auth.user.roles,
    rootArrowEnabled: builder.selectors.getConfig(
      store,
      "header.menu.self.root-arrow"
    ),
    headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
    ulClasses: builder.selectors.getClasses(store, {
      path: "header_menu_nav",
      toString: true,
    }),
    disabledAsideSelfDisplay:
      objectPath.get(store.builder.layoutConfig, "aside.self.display") ===
      false,
  };
};

export default withRouter(connect(mapStateToProps)(HMenu));

import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL_V2;

export const getChatsListTable = (params) =>
  axios.get(`${BASE_URL}chat-rooms/table`, { params });

export const getChatsList = (params) =>
  axios.get(`${BASE_URL}chat-rooms`, { params });

export const getMessagesListByChatId = (chatId) =>
  axios.get(`${BASE_URL}chat-rooms/${chatId}`);

export const getCountChats = (params) =>
  axios.get(`${BASE_URL}chat-rooms/count`, { params });

export const sendMessage = (data, chatRoomId) =>
  axios.post(`${BASE_URL}chat-rooms/${chatRoomId}/messages`, data);

export const pinMessages = (data, chatRoomId) =>
  axios.post(`${BASE_URL}chat-rooms/${chatRoomId}/messages/pin`, data);

export const unpinMessages = (data, chatRoomId) =>
  axios.post(`${BASE_URL}chat-rooms/${chatRoomId}/messages/unpin`, data);

export const markUsRead = (data) =>
  axios.post(`${BASE_URL}messages/mark-as-read`, data);

export const getChatMedia = (chatId, params) => {
  return axios.get(`${BASE_URL}chat-rooms/${chatId}/media`, { params });
};

export const getChatLinks = (chatId, params) => {
  return axios.get(`${BASE_URL}chat-rooms/${chatId}/links`, { params });
};

export const getPinnedMessages = (chatId) => {
  return axios.get(`${BASE_URL}chat-rooms/${chatId}/pinned`);
};

export const likeMessages = (chatId, mesageId) => {
  return axios.post(
    `${BASE_URL}chat-rooms/${chatId}/messages/${mesageId}/like`
  );
};

export const dislikeMessages = (chatId, mesageId) => {
  return axios.delete(
    `${BASE_URL}chat-rooms/${chatId}/messages/${mesageId}/like`
  );
};

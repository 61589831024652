import React from "react";
import styled from "styled-components";
import { CloseOutlined } from "@material-ui/icons";
import { EditIconOutlined } from "../../../../../../../../assets";
import { setViewTypeAction } from "../../../../../../../store/actions";
import { useDispatch } from "react-redux";
import { VIEW_MESSAGING_TYPE } from "../../../../constants";

export const SettingsHeader = ({ chatInfo }) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setViewTypeAction(VIEW_MESSAGING_TYPE.MESSAGES));
  };

  const handleEdit = () => {};

  return (
    <Wrapper>
      <WrapperInner>
        <span onClick={handleBack}>
          <CloseOutlined />
        </span>
        <Title>Chat Settings</Title>
        <span onClick={handleEdit}>
          <EditIconOutlined />
        </span>
      </WrapperInner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid rgba(228, 231, 236, 1);
`;
const WrapperInner = styled.div`
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  align-items: center;
`;
const Title = styled.p`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  margin: 0;
`;

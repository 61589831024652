import { useEffect, useState } from "react";
import { getChatsList } from "../../../../crud/chats.crud";
import useDebounce from "../../../../hooks/useDebounce";

const initPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 10,
  from: 0,
  to: 10,
  total: 0,
};

const tableDTO = (data) => {
  return data.map((item) => ({ values: item }));
};

export const useChatsList = ({ initialSort = ["", ""], profileId = null }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([0]);
  const [staff, setStaff] = useState([0]);
  const [status, setStatus] = useState(1);

  const [pagination, setPagination] = useState(initPagination);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const params = {
          page,
          per_page: perPage,
          group: groups.filter(Boolean),
          staff: staff.filter(Boolean),
          search: debouncedSearch,
          profile_status: !!status ? [status] : undefined,
          sort_field: sort?.[0],
          sort_direction: sort?.[1],
          profile: profileId ? [profileId] : undefined,
        };
        const res = await getChatsList(params);

        setData(tableDTO(res.data.data));

        setPagination(res.data.meta);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [groups, staff, debouncedSearch, page, perPage, status, sort]);

  return {
    loading,
    error,
    data,
    search,
    setSearch,
    setSort,
    sort,
    groups,
    setGroups,
    staff,
    setStaff,
    setStatus,
    status,

    pagination,
    setPagination,
    page,
    perPage,
    setPage,
    setPerPage,
    setData,
  };
};

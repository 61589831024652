import React, { useState, useEffect } from "react";
import {
  getChatMedia,
  getChatLinks,
} from "../../../../../../../crud/chats.crud";
import { ImageList, ImageListItem } from "@material-ui/core";
import styled from "styled-components";
import { ImgComponent } from "../../../../../Sales/helpers/ImgComponent";
import { extractLinks } from "../../../../helpers/extractLinks";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Message } from "../../../Messages/components/Message/Message";
const TYPE = {
  MEDIA: 1,
  DOCS: 2,
  LINKS: 3,
};

const extension = {
  [TYPE.MEDIA]: ["jpg", "jpeg", "png", "bmp", "gif", "heic"],
  [TYPE.DOCS]: [
    "doc",
    "dock",
    "docx",
    "xls",
    "xlsx",
    "zip",
    "pdf",
    "txt",
    "scv",
  ],
};

function StandardImageList({ itemData }) {
  return (
    <ImageList sx={{ width: 300, height: 450 }} cols={3} rowHeight={164}>
      {itemData.map((item) => (
        <ImageListItem key={item.id}>
          <ImgComponent className="image-chat" src={item.path} />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function StandardDocList({ itemData, onDownload }) {
  return (
    <ImageList sx={{ width: 300, height: 450 }} cols={1} rowHeight={44}>
      {itemData.map((file) => (
        <ImageListItem key={file.id}>
          <FileType onClick={onDownload}>
            {file.extension.toUpperCase()}{" "}
            <FileName>{`${file.file_name}.${file.extension}`}</FileName>{" "}
          </FileType>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function StandardLinksList({ itemData, onDownload }) {
  return (
    <ImageList sx={{ width: 300, height: 450 }} cols={1} rowHeight={44}>
      {itemData.map((file) => {
        return <Message key={file.id} message={file} />;
        // const links = extractLinks(file.text);

        // return links.map((link, idx) => (
        //   <ImageListItem key={idx}>
        //     <FileName>
        //       <Link to={link}>{link}</Link>
        //     </FileName>{" "}
        //   </ImageListItem>
        // ));
      })}
    </ImageList>
  );
}

export const Files = ({ value, chatRoomId }) => {
  const [data, setData] = useState([]);

  const getMedia = async () => {
    const res = await getChatMedia(chatRoomId, { extention: extension[value] });
    setData(res.data);
  };
  const getDocs = async () => {
    const res = await getChatMedia(chatRoomId, { extention: extension[value] });
    setData(res.data);
  };
  const getLinks = async () => {
    const res = await getChatLinks(chatRoomId);
    setData(res.data);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        if (value === TYPE.MEDIA) getMedia();
        if (value === TYPE.DOCS) getDocs();
        if (value === TYPE.LINKS) getLinks();
      } catch (error) {}
    };
    if (chatRoomId && value) fetch();
  }, [value, chatRoomId]);
  return (
    <Wrapper>
      {[TYPE.MEDIA].includes(value) && <StandardImageList itemData={data} />}
      {[TYPE.DOCS].includes(value) && <StandardDocList itemData={data} />}
      {[TYPE.LINKS].includes(value) && <StandardLinksList itemData={data} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .image-chat {
    width: 100%;
    height: auto;
  }
`;

const FileType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: #555;
  text-transform: uppercase;
  width: 35px;
  height: 22px;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  opacity: 0px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  background-color: black;
  color: white;
  cursor: pointer;
`;

const FileName = styled.p`
  margin: 0;
  color: black;
  text-transform: unset;
`;

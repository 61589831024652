import { ChatDrawer } from "./components/ChatDrawer/ChatDrawer";
import { ChatsHeader } from "./components/ChatsHeader/ChatsHeader";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { ChatsList } from "./components/ChatsList/ChatsList";
import { Messages } from "../Messages/Messages";
import { useSelector, useDispatch } from "react-redux";
import { MessagesHeader } from "../Messages/components/MessagesHeader/MessagesHeader";
import { SendMessage } from "../SendMessage/SendMessage";
import {
  fetchChatsAction,
  sendMessageAction,
  setWSEventMessageAction,
  setWSMessageChangeNameAction,
  setWSMessagePinnedAction,
  setWSMessageReadAction,
  setWSMessageUnPinnedAction,
} from "../../../../../store/actions";

import { useContext, useRef } from "react";
import { SocketContext } from "../../../../../services/socket/socket";
import { VIEW_MESSAGING_TYPE } from "../../constants";
import { ChatSettings } from "../ChatSettings/ChatSettings";
import { SettingsHeader } from "../ChatSettings/components/SettingsHeader/SettingsHeader";
import {
  setFilesAction,
  setMoreChatsAction,
} from "../../../../../store/actions";

export const Chats = () => {
  const wrapperRef = useRef(null);

  const { chatRoom } = useContext(SocketContext);
  const meId = useSelector((state) => state?.auth?.user?.id);
  const files = useSelector((state) => state.chat.files);

  const [message, setMessage] = useState({ text: "" });
  const { chats, messages, view_type } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setMessage((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };

  const handleSend = () => {
    dispatch(
      sendMessageAction({
        message: {
          text: message.text,
          media: files?.map((item) => item.data.id),
        },
        chatRoomId: chats.currentChatId,
      })
    );
    setMessage({ text: "" });
    dispatch(setFilesAction([]));
  };

  // const handleScroll = () => {
  //   const wrapper = wrapperRef.current;

  //   if (wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight - 50) {
  //     dispatch(setMoreChatsAction({}));
  //   }
  // };

  // useEffect(() => {
  // const wrapper = wrapperRef.current;
  // if (wrapper) {
  //     wrapper.addEventListener("scroll", handleScroll);
  //   }
  //
  //   return () => {
  //     if (wrapper) {
  //       wrapper.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [chats.length]);

  const renderBody = () => {
    if (view_type === VIEW_MESSAGING_TYPE.CHATS) {
      return <ChatsList chats={chats.chatList} />;
    }
    if (view_type === VIEW_MESSAGING_TYPE.MESSAGES) {
      return (
        <Messages
          loading={messages.loading}
          messages={messages?.messagesList[chats.currentChatId]}
        />
      );
    }
    if (view_type === VIEW_MESSAGING_TYPE.SETTINGS) {
      return (
        <ChatSettings
          loading={messages.loading}
          messages={messages?.messagesList[chats.currentChatId]}
          chatInfo={messages?.messagesList[chats.currentChatId]}
        />
      );
    }
  };

  const renderHeader = () => {
    if (view_type === VIEW_MESSAGING_TYPE.CHATS) {
      return (
        <ChatsHeader chatInfo={messages?.messagesList[chats.currentChatId]} />
      );
    }
    if (view_type === VIEW_MESSAGING_TYPE.MESSAGES) {
      return (
        <MessagesHeader
          chatInfo={messages?.messagesList[chats.currentChatId]}
        />
      );
    }
    if (view_type === VIEW_MESSAGING_TYPE.SETTINGS) {
      return (
        <SettingsHeader
          chatInfo={messages?.messagesList[chats.currentChatId]}
        />
      );
    }
  };

  useEffect(() => {
    if (view_type === VIEW_MESSAGING_TYPE.CHATS) {
      dispatch(fetchChatsAction({ page: 1 }));
    }
  }, [view_type]);

  useEffect(() => {
    if (chatRoom && window.location.pathname !== "/chats-list") {
      chatRoom.bind("message-created", ({ message }) => {
        if (meId === message.created_by.id) return;
        dispatch(setWSEventMessageAction(message));
      });

      chatRoom.bind("message-pinned", ({ message }) => {
        dispatch(setWSMessagePinnedAction(message));
      });

      chatRoom.bind("message-unpinned", ({ message }) => {
        dispatch(setWSMessageUnPinnedAction(message));
      });
      chatRoom.bind("message-read", ({ message }) => {
        if (meId === message.created_by.id) return;
        dispatch(setWSMessageReadAction(message));
      });
      chatRoom.bind("chat-room-name-changed", ({ message }) => {
        if (meId === message.created_by.id) return;
        dispatch(setWSMessageChangeNameAction(message));
      });
    }
  }, [chatRoom]);

  return (
    <ChatDrawer>
      <Wrapper>
        {renderHeader()}
        <Content ref={wrapperRef}>{renderBody()}</Content>
        <SendMessageContainer>
          {" "}
          {view_type === VIEW_MESSAGING_TYPE.MESSAGES && (
            <SendMessage
              onChange={handleChange}
              value={message}
              onSend={handleSend}
            />
          )}
        </SendMessageContainer>
      </Wrapper>
    </ChatDrawer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SendMessageContainer = styled.div`
  margin-top: auto;
`;

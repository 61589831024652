import React from "react";
import styled from "styled-components";
import { MessageTableIcon } from "../../../../../../../assets";
import { useDispatch } from "react-redux";
import {
  toggleChatAction,
  setChatIdAction,
  setViewTypeAction,
} from "../../../../../../store/actions";

import { VIEW_MESSAGING_TYPE } from "../../../constants";

export const OpenChatColumn = ({ record }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleChatAction());
    dispatch(setChatIdAction(record.id));
    dispatch(setViewTypeAction(VIEW_MESSAGING_TYPE.MESSAGES));
  };
  return (
    <Wrapper onClick={handleClick}>
      <MessageTableIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 56px;
  height: 32px;
  padding: 6px 16px 6px 16px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid rgba(196, 196, 196, 1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SET_CRM_LIST = "SET_CRM_LIST";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
export const SET_CRM_FIELDS = "SET_CRM_FIELDS";
export const RESET_CRM_FIELDS = "RESET_CRM_FIELDS";
export const SET_CURRENT_MAPPING = "SET_CURRENT_MAPPING";
export const SET_REFRESH_DATE_GROUPS = "SET_REFRESH_DATE";
export const SET_REFRESH_DATE_USERS = "SET_REFRESH_DATE_USERS";
export const SET_REFRESH_DATE_PROFILES = "SET_REFRESH_DATE_PROFILES";
export const TYPE_NOTEPAD_TEXT = "TYPE_NOTEPAD_TEXT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_TEMPORARY_FORMS = "SET_TEMPORARY_FORMS";
export const RESET_TEMPORARY_FORM = "RESET_TEMPORARY_FORM";
export const RESET_FORMS = "RESET_FORMS";
export const ADD_FILTERS_PROFILE_LIST = "ADD_FILTERS_PROFILE_LIST";
export const SET_PROFILE = "SET_PROFILE";

// export const REMOVE_FILTERS_PROFILE_LIST = 'REMOVE_FILTERS_PROFILE_LIST';
//chat

export const TOGGLE_CHAT = "TOGGLE_CHAT";

export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const SET_CHAT_TYPE_VIEW = "SET_CHAT_TYPE_VIEW";
export const SET_CURRENT_CHAT_ID = "SET_CURRENT_CHAT_ID";
export const SET_CURRENT_CHATS_LIST = "SET_CURRENT_CHATS_LIST";

export const SET_CURRENT_MESSAGE_LIST = "SET_CURRENT_MESSAGE_LIST";
export const START_LOADING_CHATS = "START_LOADING_CHATS";
export const STOP_LOADING_CHATS = "STOP_LOADING_CHATS";
export const FETCH_CHATS = "FETCH_CHATS";

export const START_LOADING_MESSAGES = "START_LOADING_MESSAGES";
export const STOP_LOADING_MESSAGES = "STOP_LOADING_MESSAGES";

export const FETCH_MESSAGES = "FETCH_MESSAGES";

export const START_SENDING_MESSAGE = "START_SENDING_MESSAGE";
export const STOP_SENDING_MESSAGE = "STOP_SENDING_MESSAGE";

export const SEND_MESSAGE = "SEND_MESSAGE";

export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";

export const SET_WS_MESSAGE = "SET_WS_MESSAGE";

export const SET_WS_MESSAGE_READ = "SET_WS_MESSAGE_READ";

export const SET_WS_MESSAGE_CHANGE_NAME = "SET_WS_MESSAGE_CHANGE_NAME";

export const SET_WS_MESSAGE_PINNED = "SET_WS_MESSAGE_PINNED";
export const SET_WS_MESSAGE_UNPINNED = "SET_WS_MESSAGE_UNPINNED";

export const START_READING_MESSAGES = "START_READING_MESSAGES";
export const STOP_READING_MESSAGES = "STOP_READING_MESSAGES";
export const READ_MESSAGES = "READ_MESSAGES";
export const READ_MESSAGES_ACTION = "READ_MESSAGES_ACTION";

export const START_PIN_MESSAGES = "START_PIN_MESSAGES";
export const STOP_PIN_MESSAGES = "STOP_PIN_MESSAGES";
export const PIN_MESSAGES = "PIN_MESSAGES";
export const PIN_MESSAGES_ACTION = "PIN_MESSAGES_ACTION";

export const START_UNPIN_MESSAGES = "START_UNPIN_MESSAGES";
export const STOP_UNPIN_MESSAGES = "STOP_UNPIN_MESSAGES";
export const UNPIN_MESSAGES = "UNPIN_MESSAGES";
export const UNPIN_MESSAGES_ACTION = "UNPIN_MESSAGES_ACTION";

export const START_PINNED_MESSAGES = "START_PINNED_MESSAGES";
export const STOP_PINNED_MESSAGES = "STOP_PINNED_MESSAGES";
export const PINNED_MESSAGES = "PINNED_MESSAGES";
export const PINNED_MESSAGES_ACTION = "PINNED_MESSAGES_ACTION";

export const OPEN_UPLOAD_MODAL = "OPEN_UPLOAD_MODAL";
export const CLOSE_UPLOAD_MODAL = "CLOSE_UPLOAD_MODAL";
export const SET_FILES = "SET_FILES";
export const REMOVE_FILE = "REMOVE_FILE";

export const GET_MODE_START = "GET_MODE_START";
export const GET_MODE_END = "GET_MODE_END";
export const GET_MORE_CHATS = "GET_MORE_CHATS";
export const GET_MORE_CHATS_ACTION = "GET_MORE_CHATS_ACTION";

export const START_LIKE_MESSAGES = "START_LIKE_MESSAGES";
export const STOP_LIKE_MESSAGES = "STOP_LIKE_MESSAGES";
export const LIKE_MESSAGES = "LIKE_MESSAGES";
export const LIKE_MESSAGES_ACTION = "LIKE_MESSAGES_ACTION";

export const START_DISLIKE_MESSAGES = "START_DISLIKE_MESSAGES";
export const STOP_DISLIKE_MESSAGES = "STOP_DISLIKE_MESSAGES";
export const DISLIKE_MESSAGES = "DISLIKE_MESSAGES";
export const DISLIKE_MESSAGES_ACTION = "DISLIKE_MESSAGES_ACTION";
